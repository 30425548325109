import React from 'react'
import styles from "./joinOurTeam.module.css"
import JoinTeam from '../joinTeam/JoinTeam'
import { Link } from 'react-router-dom'
const JoinOurTeam = () => {
    return (
        <div className={styles.container}>
        <div className={styles.contents}>
     
     <div className={styles.box}>
    
    <div className={styles.section}>
    
    <div className={styles.content}>
    
    <p className={styles.subtitle}>Our Team</p>
               <p className={styles.text}>Become a part of our big family to inspire and get inspired by professional experts </p>
             
    
    
    </div>
    </div>
    <div className={styles.section1}>
    
    <div className={styles.content}>
    <Link to="/services">
                
                <button>Join our Team</button>
                </Link>
    </div>
    </div>
    </div>
     </div>
  
     </div>
            
      )
    }

export default JoinOurTeam
