import React from 'react'
import Hero2 from '../hero2/Hero2'
import ContactSheet from '../../smallcomponents/contactSheet/ContactSheet'
import GlobalGrowing from '../../smallcomponents/globalGrowing/GlobalGrowing'
import CareersLast from '../../smallcomponents/careersLast/CareersLast'

const Cantact = () => {
  return (
    <>
    <Hero2 
   
    title='Cantact Us'
    subtitle="Get in Touch"
     bg='black'

   
    />
 <ContactSheet/>
 <GlobalGrowing/>
 <CareersLast/>
    </>
  )
}

export default Cantact