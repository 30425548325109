import React from 'react';
import Select from 'react-select';
import styles from './careersSheet.module.css';

import { Uploade } from '../../components/Svg';
const jobOptions = [
  { value: 'sales', label: 'Sales' },
  { value: 'digital-marketing', label: 'Digital Marketing' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Software', label: 'Software' },
  { value: 'IT Support', label: 'IT Support' },
  { value: 'Customer Care', label: 'Customer Care' },
  { value: 'Receptionist', label: 'Receptionist' },
  { value: 'Telephone Operator', label: 'Telephone Operator' },
];

const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6200ea' : state.isFocused ? '' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      padding: '10px',
      borderBottom: '1px solid #ccc'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#000',
      
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%', // Set the width of the dropdown menu
      height: "300px",
  
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '500px', // Set the max-height of the dropdown menu
      overflowY: 'auto',
      borderRadius: '10px', // Add border radius to the dropdown menu
      boxShadow: '0 4px 80px rgba(0, 0, 0, 0.1)'
    }),
  };
  

const ContactSheet = () => {
  return (
    <div className={styles.container}>
       
      <div className={styles.form}>
        <div className={styles.inputBox}>
          <label>Full Name *</label>
          <input type="text" placeholder="Enter your full name" />
        </div>
        <div className={styles.inputBox}>
          <label>Your Email *</label>
          <input type="email" placeholder="example@yourmail.com" />
        </div>
        <div className={styles.inputBox}>
          <label>Phone Number *</label>
          <input type="tel" placeholder="Enter phone number" />
        </div>
        <div className={styles.inputBox}>
          <label>Job Category *</label>
          <Select
            options={jobOptions}
            styles={customStyles}
            placeholder="Select job category"
          />
        </div>
        <div className={styles.inputBox}>
          <label>Upload Resume *</label>
          <div className={styles.uploadContainer}>
            <input type="file" id="file-upload" />
            <label htmlFor="file-upload" className={styles.uploadLabel}>
                <div className={styles.upload_time}>
              <Uploade />
             <h1 className={styles.upload_text}> Upload file</h1>
             </div>
            </label>
          </div>
        </div><br/>
        <div className={styles.button}>
          <button>Send Message</button>
        </div>
       
      </div>
     </div>
     
  );
};

export default ContactSheet;
