import React from 'react'
import Hero2 from '../hero2/Hero2'
import CareersSheet from '../../smallcomponents/careersSheet/CareersSheet'
import CareersChart from '../../smallcomponents/careersChart/CareersChart'
import CareersLast from '../../smallcomponents/careersLast/CareersLast'

const Services = () => {
  return (
    <>
    <Hero2 
   
    title='Careers'
    subtitle="Join our team"
  

    bg='black'
   
    />
    <CareersChart/>
    <CareersSheet/>
    <CareersLast/>
    </>
  )
}

export default Services
