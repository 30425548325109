import React from 'react'
import styles from "./experience.module.css"
import Customers from '../customers/Customers'

const Experience = () => {
  return (
    <div className={styles.container}>
         <div className={styles.content}>
     
    <h2>What our customers say</h2>
      <p className={styles.title}>"Thank you for your trust in Crypt Land! We are grateful for your feedback and are committed to providing the best [products/services offered]. Read what our clients have to say about their experience with us.</p>
      
      </div>
     <Customers/>
      </div>
  )
}

export default Experience
