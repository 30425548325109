import React from 'react'
import styles from "./globalGrowing.module.css"
import { Aus, Uk, Usa } from '../../components/Svg'
import { Link, NavLink } from 'react-router-dom'

const GlobalGrowing = () => {
  return (
    <div className={styles.container}>
         <div className={styles.content5}>
       <div className={styles.frist}>
    <h2 className={styles.sub_titele}>We’re Global and Growing</h2>
      <p className={styles.title}></p>
      </div>
      <div className={styles.box}>
    
  
 
 <div className={styles.section}>
 
  <div className={styles.content}>
  
<Uk/>
  <p className={styles.subtitle}>India</p>
  <p className={styles.text}>Bangalore, karnataka</p>
  
  </div>
  </div>
</div>
      </div>
      </div>
  )
}

export default GlobalGrowing
