import React from 'react'
import styles from './aboutcompany.module.css'
import { BottomLine, Comma, Star } from '../../components/Svg'
import { Link } from 'react-router-dom'
const AboutCompan = () => {
  return (
    <div className={styles.container}>
        <div className={styles.content}>
      <div className={styles.left}>
        <h1 className={styles.heading}>Few lines About the company</h1>
        <div className={styles.maincontainer}>
              <img src="./assets/images/Photo45.png" className={styles.mainImg} />
            </div>
            <div className={styles.lastcontainer}>
            <div className={styles.clints}>
                <h2 className={styles.num}>Happy</h2>
                <h3 className={styles.n_text}>clients</h3>
            </div>
            <div className={styles.smallimg}>
              <img src="./assets/images/Photo66.png" className={styles.lastImg} />
            </div>
            </div>

      </div>
      <div className={styles.right}>
      <p className={styles.text}>ARaDee ventures has been at the forefront of the software industry, delivering cutting-edge solutions to businesses across various sectors. </p>
      
      <h4 className={styles.title}>We're on a mission to bring ideas to life and trying to be a pioneer in this segment</h4>
      
      <h4 className={styles.textline}>Our journey began with a shared vision between two passionate individuals, Mr.Rahul and Ms.Deeksha. Drawing on years of experience across various industry segments, they united to create a company built on the pillars of commitment, innovation, and unparalleled service.</h4>
      <h4 className={styles.textline}>Together, we share our commitment towards quality and service. Our combined experience ensures that we not only meet but exceed the expectations of our clients, delivering results that make a difference.
<br/><br/>
Thank you for being a part of our journey.</h4>
      <hr></hr>
      <div className={styles.join}>
                <Link to="/services">
                
      <button>Join our Team</button>
      </Link></div>
      
      </div>
      
    </div>
    </div>
  )
}

export default AboutCompan
