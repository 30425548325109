import React from 'react'
import styles from "./total.module.css"
const Total = () => {
  return (
    <div className={styles.container}>
       

  <div className={styles.box}>
    
    <div className={styles.section}>
  
  <div className={styles.content}>
    
  <p className={styles.subtitle}>4.5k+</p>
                <p className={styles.text}>Total users</p>
               

  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
    
  <p className={styles.subtitle}>4.5k+</p>
  <p className={styles.text}>Total users </p>
 
 
  </div>

 </div>
 
  <div className={styles.section}>
  
  <div className={styles.content}>
    
  <p className={styles.subtitle}>4.5k+</p>
  <p className={styles.text}>Total users</p>

 
  </div>
  </div>
</div>
  </div>

  )
}

export default Total
