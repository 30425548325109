import React from 'react'
import styles from "./mobas.module.css"
import { AppStrore, PlayStore } from '../../components/Svg'
const Mobile = () => {
  return (
    <div className={styles.contasiner}>
      <div className={styles.left_con}>
    
        <p className={styles.textS1}>Manage all projects from your mobile</p>
        <h1 className={styles.textS2}>Download the app to manage your projects, keep track of the progress and complete tasks without procastinating. Stay on track and complete on time!</h1>
        <p className={styles.textS3}>Get the App</p>
        <div className={styles.Stores}>
<PlayStore/>
<AppStrore/>
        </div>
      </div>

      
      <div className={styles.right_con}>
      <div className={styles.Imagecontainer}>
<div className={styles.maincontainer}>
              <img src="./assets/images/Image55.png" className={styles.mainImg} />
            </div>
            
</div>
     </div>

    </div>
  )
}

export default Mobile
