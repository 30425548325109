import React from 'react'
import styles from "./branding.module.css"

const JoinOurTeam = () => {
    return (
        <div className={styles.container}>
        <div className={styles.contents}>
     
     <div className={styles.box}>
    
    <div className={styles.section}>
    
    <div className={styles.content}>
    
    <p className={styles.subtitle}>Be a branding partner or grow your business</p>
               <p className={styles.text}> </p>
     </div>
    </div>
    <div className={styles.section1}>
    
    <div className={styles.content}>
    <button>Join Now</button>
    </div>
    </div>
    
    
    </div>
    <div className={styles.section2}>
    
    <div className={styles.content}>
   

              <img src="./assets/images/Pexels1.png" className={styles.mainImg} />
          
            
            </div>
            </div>
     </div>
  
     </div>
            
      )
    }

export default JoinOurTeam
