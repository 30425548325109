import React from 'react'
import styles from './solve.module.css'
import {  Eicon1, Eicon2, Eicon3, Eicon4, Eicon5, Eicon6 } from '../../components/Svg'

const Solve = () => {
  return (
    <div className={styles.container}>
     
<div className={styles.box1}>
    
    <div className={styles.section}>
  
  <div className={styles.content}>
  <div className={styles.con_1}>
    <Eicon1/>
    </div>
    <div className={styles.cont_2}>
  <p className={styles.subtitle}>Unlimited Projects</p>
                <p className={styles.text}>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
              
</div>
  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
  <div className={styles.con_1}>
    <Eicon2/>
    </div>
    <div className={styles.cont_2}>
  <p className={styles.subtitle}>Team Management</p>
                <p className={styles.text}>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
              
</div>
  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
  <div className={styles.con_1}>
    <Eicon3/>
    </div>
    <div className={styles.cont_2}>
  <p className={styles.subtitle}>File Sharing</p>
                <p className={styles.text}>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
              
</div>
  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
  <div className={styles.con_1}>
    <Eicon4/>
    </div>
    <div className={styles.cont_2}>
  <p className={styles.subtitle}>Video Meetings</p>
                <p className={styles.text}>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
              
</div>
  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
  <div className={styles.con_1}>
    <Eicon5/>
    </div>
    <div className={styles.cont_2}>
  <p className={styles.subtitle}>Time Tracking</p>
                <p className={styles.text}>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
              
</div>
  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
  <div className={styles.con_1}>
    <Eicon6/>
    </div>
    <div className={styles.cont_2}>
  <p className={styles.subtitle}>Payment System</p>
                <p className={styles.text}>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
              
</div>
  </div>

 </div>
</div>
</div>
  )
}

export default Solve
