import React from 'react'
import styles from "./careersChart.module.css"
const CareersChart = () => {
  return (
    <div>
      <div className={styles.container}>
      <div className={styles.contant}>
      <h5 className={styles.heading}>Become a part of our big familyto inspire and get inspired by  <span className={styles.highlight}> professional experts.</span></h5>
      </div>

      </div>
    </div>
  )
}

export default CareersChart
