import React from 'react'
import styles from './business.module.css'

const Business = () => {
  return (
    <div className={styles.container}>
         <div className={styles.contents}>
       <div className={styles.frist}>
    <h2>How To Grow Your Business</h2>
      <p className={styles.title}></p>
      </div>
      <div className={styles.box}>
    
    <div className={styles.section}>
  
  
  <div className={styles.imageArea}>
  <div className={styles.Area}></div>
  <div className={styles.imag}style={{background:`url('/assets/images/image96.png')`, backgroundRepeat:'no-repeat',backgroundSize:'40px'}}></div>
  </div>
  <p className={styles.subtitle}>Custom Software Development</p>
                <p className={styles.text}>We create tailored software solutions that meet the unique needs of your business. From concept to deployment, we ensure a seamless development process.</p>
              

  

 </div>
 <div className={styles.section}>
  

  <div className={styles.imageArea}>
  <div className={styles.Area1}></div>
  <div className={styles.imag}style={{background:`url('/assets/images/image97.png')`, backgroundRepeat:'no-repeat',backgroundSize:'40px'}}></div>
  </div>
  <p className={styles.subtitle}>Web Development</p>
  <p className={styles.text}>Our team designs and develops responsive, user-friendly websites that provide an optimal experience on any device.</p>
 
 
 

 </div>
 <div className={styles.section}>
  

  <div className={styles.imageArea}>
  <div className={styles.Area2}></div>
  <div className={styles.imag}style={{background:`url('/assets/images/image98.png')`, backgroundRepeat:'no-repeat',backgroundSize:'40px'}}></div>
  </div>  
  <p className={styles.subtitle}>Mobile App Developement  </p>
  <p className={styles.text}>We build intuitive and engaging mobile applications for both iOS and Android platforms, ensuring your business is accessible on-the-go.</p>
  
 
  
  </div>
  <div className={styles.section}>
  

  <div className={styles.imageArea}>
  <div className={styles.Area3}></div>
  <div className={styles.imag}style={{background:`url('/assets/images/image97.png')`, backgroundRepeat:'no-repeat',backgroundSize:'40px'}}></div>
  </div>
  <p className={styles.subtitle}>Cloud Solutions</p>
  <p className={styles.text}>Leverage the power of cloud computing with our scalable and secure cloud solutions, designed to enhance your operational efficiency.</p>
 
 
 

 </div>
 <div className={styles.section}>
  

  <div className={styles.imageArea}>
  <div className={styles.Area4}></div>
  <div className={styles.imag}style={{background:`url('/assets/images/image96.png')`, backgroundRepeat:'no-repeat',backgroundSize:'40px'}}></div>
  </div>
  <p className={styles.subtitle}>Consulting & Strategy</p>
  <p className={styles.text}>Our experts provide strategic guidance and consulting services to help you navigate the complex landscape of technology and achieve your business goals.</p>
 
 
 

 </div>
</div>
      </div>
      </div>
  )
}

export default Business
