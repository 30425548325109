import React from 'react'
import styles from "./ourMission.module.css"
import { Right } from '../../components/Svg'

const OurMission = () => {
  return (
    <div className={styles.container}>

<div className={styles.box}>
 
 <div className={styles.section1}>

<div className={styles.content}>
 
<p className={styles.subtitle}>Our Vision</p>
             <p className={styles.text}>To be the leading provider of software solutions, known for our quality, innovation, and customer-centric approach. </p>
            

</div>

</div>
<div className={styles.section2}>

<div className={styles.content}>
<div className={styles.Imagecontainer}>
<div className={styles.maincontainer}>
              <img src="./assets/images/Photo46.png" className={styles.mainImg} />
            </div>
            
</div>
</div>


</div>
<div className={styles.section3}>

<div className={styles.content}>
<div className={styles.Imagecontainer}>
<div className={styles.maincontainer}>
              <img src="./assets/images/Photo47.png" className={styles.mainImg} />
            </div>
            
</div>
</div>
</div>

</div>
</div>
  )
}

export default OurMission
