import React, { useState } from 'react';
import styles from './contactSheet.module.css';

const ContactSheet = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [nameError, setNameError] = useState('');

  const phoneRegex = /^[0-9]{10}$/;
  const nameRegex = /^[a-zA-Z\s]+$/; 

  const handleNameChange = (e) => {
    const { value } = e.target;
    if (!nameRegex.test(value)) {
      setNameError('Please enter a valid name (letters and spaces only).');
    } else {
      setNameError('');
    }
    setFullName(value);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    if (!phoneRegex.test(value)) {
      setPhoneError('Please enter a valid 10-digit phone number.');
    } else {
      setPhoneError('');
    }
    setPhoneNumber(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!nameRegex.test(fullName)) {
      setNameError('Please enter a valid name (letters and spaces only).');
      return;
    }
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneError('Please enter a valid 10-digit phone number.');
      return;
    }
    // Handle form submission logic here
    console.log(fullName, email, phoneNumber, company, subject, message);
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputBox}>
          <label>Full Name *</label>
          <input
            type="text"
            placeholder="Enter your full name"
            value={fullName}
            onChange={handleNameChange}
            required
          />
          {nameError && <span className={styles.error}>{nameError}</span>}
        </div>
        <div className={styles.inputBox}>
          <label>Your Email *</label>
          <input
            type="email"
            placeholder="example@yourmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputBox}>
          <label>Phone Number *</label>
          <input
            type="tel"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={handlePhoneChange}
            required
          />
          {phoneError && <span className={styles.error}>{phoneError}</span>}
        </div>
        <div className={styles.inputBox}>
          <label>Company</label>
          <input
            type="text"
            placeholder="Company name"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>
        <div className={styles.inputBox1}>
          <label>Subject *</label>
          <input
            type="text"
            placeholder="How can we help..."
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div><br/>
        <div className={`${styles.textarea} ${styles.inputBox1}`}>
          <label>Message</label>
          <textarea
            placeholder="Hello there, I would like to talk about how to..."
            cols="30"
            rows="6"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div><br/>
        <div className={styles.button}>
          <button type="submit">Send Message</button>
        </div>
      </form>
   
    </div>
  );
};

export default ContactSheet;
