import React from 'react'
import styles from "./problems.module.css"
import Solve from '../solve/Solve'

const Problems = () => {
  return (
    <>
    <div className={styles.container}>
      <div className={styles.Imagecontainer}>

<img src="./assets/images/Image45.png" className={styles.mainImg} />

</div>
<div className={styles.text_head}>
<p className={styles.heading_v}>We made this app 
to solve your problems.</p>
</div>
<Solve/>
    </div>
   
    </>
  )
}

export default Problems
