import React from 'react'

import ContactSheet from '../contactSheet/ContactSheet'
import Hero2 from '../../components/hero2/Hero2'

const ContactUs = () => {
  return (
    <>
    <Hero2 
   
    title='Cantact Us'
   text="Get a free consultancy from our expert right now!"

   
    />
    <ContactSheet/>
    </>
 
  )
}

export default ContactUs
