import React from 'react'
import styles from './hero2.module.css'
import CantactCard from './ContactCard'




const Hero2 = ({ title, subtitle, paragraph, bg, text }) => {
    return (
        <div style={{background: bg ? `${bg}` : 'black'}}>
        <div className={styles.container}>
            <div className={styles.left}>
                <h2>{title} </h2>
                <p>{subtitle}</p>
                <h3>{paragraph} </h3>
               <h6>{text}</h6>
             <CantactCard/>
            </div>

            </div>  
        </div>
        
       
    )
}

export default Hero2
