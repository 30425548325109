// src/Pricing.js
import React from 'react';
import styles from './payment.module.css';
import { Licon1, Licon2 } from '../../components/Svg';

const Payment = () => {
  return (
    <div className={styles.container}>
       

      <div className={styles.card}>
        <div className={styles.plan}>
          <h3>Personal</h3>
          <p className={styles.price}>$19<span className={styles.changs}>/ month</span></p>
          <p className={styles.description}>All the basic features to boost your freelance career</p>
          <hr></hr>
          <ul>
          
            <li> <Licon1/><span className={styles.text_lft}> Full Access to Landingfolio</span><span className={styles.text_lft}> <Licon2/></span></li>
            <li> <Licon1/><span className={styles.text_lft}>100 GB Free Storage</span><span className={styles.text_lft}> <Licon2/></span></li>
            <li> <Licon1/><span className={styles.text_lft}>Unlimited Visitors</span></li>
            <li> <Licon1/><span className={styles.text_lft}>10 Agents</span></li>
            <li> <Licon1/><span className={styles.text_lft}>Live Chat Support</span><span className={styles.text_lft}> <Licon2/></span></li>
          </ul>
          <button className={styles.button}>Get 14 Days Free Trial</button>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.plan}>
          <h3>Professional</h3>
          <p className={styles.price}>$49<span className={styles.changs}>/ month</span></p>
          <p className={styles.description}>All the basic features to boost your freelance career</p>
          <hr></hr>
          <ul>
          <li> <Licon1/><span className={styles.text_lft}> Full Access to Landingfolio</span><span className={styles.text_lft}> <Licon2/></span></li>
            <li> <Licon1/><span className={styles.text_lft}>100 GB Free Storage</span><span className={styles.text_lft}> <Licon2/></span></li>
            <li> <Licon1/><span className={styles.text_lft}>Unlimited Visitors</span></li>
            <li> <Licon1/><span className={styles.text_lft}>10 Agents</span></li>
            <li> <Licon1/><span className={styles.text_lft}>Live Chat Support</span><span className={styles.text_lft}> <Licon2/></span></li>
          </ul>
          <button className={`${styles.button} ${styles.professionalButton}`}>Get 14 Days Free Trial</button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
