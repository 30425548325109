import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./sampleGet.module.css";
const SampleGet = () => {
    const [store, setStore] = useState([]);
    const getDetails = async () => {
        try {
            const response = await axios.get("https://api.sampleapis.com/beers/ale");
            console.log(response);
            if (response.data) {
                setStore(response.data);
            }
        } catch (err) {
            console.log(err);
        } finally {
        }
    };

    const deleteData =async (id)=>{
          try{
                const response =await axios.delete(`https://api.sampleapis.com/beers/ale/${id}`)
                if(response.data){
                    console.log("heyyy delteddddd")
                    getDetails()
                }
          }catch(err){
              console.log(err)
          }
    }
    useEffect(() => {
        getDetails();
    }, []);


    return (
        <div>
            {store.map((item) => (
                <div key={item.id} className={styles.items1}>
                    <p className={styles.title}> {item.id}</p>

                    <p className={styles.name}>name :{item.name}</p>
                    <p className={styles.price}>price :{item.price}</p>
                    <button onClick={()=>deleteData(item.id)}>delete</button>
                </div>
            ))}
        </div>
    );
};

export default SampleGet;
