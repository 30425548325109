import React from 'react'
import styles from "./etravellerFrist.module.css"

import Brands from '../brands/Brands'
import Delivery from '../delivery/Delivery'
import Problems from "../problems/Problems"
import Branding from "../branding/Branding"

import Easypay from '../easypay/Easypay'

import Experience from '../Experience/Experience'
import ServiceLocation from '../serviceLocation/ServiceLocation'
import Total from '../total/Total'
import FAQ from '../faq/FAQ'

import ContactUs from '../contactUs/ContactUs'
import Mobile from '../mobile/Mobile'
import { AppStrore, PlayStore } from '../../components/Svg'



const EtravellerFst = () => {
  return (
    <>
    <div className={styles.container}>
        <div className={styles.bag_text}>
            <p className={styles.text_12}>E-traveller</p>
        </div>
   
<div className={styles.box}>
 
 <div className={styles.section1}>

<div className={styles.content}>
 
<p className={styles.subtitle}>India’s 1st <br/>E-Traveller connecting professionals</p>
             <p className={styles.text}>From the small stuff to the big picture, organizes the work so teams know what to do, why it matters, and how to get it done. </p>
            <div className={styles.stors}>
            <div className={styles.stors1}>
             <button>Get Started</button>
             </div>
             <div className={styles.stors2}>
             <PlayStore/>
             <AppStrore/>
             </div>
             </div>
            </div>
</div>
<div className={styles.section2}>

<div className={styles.content}>
<div className={styles.Imagecontainer}>
<div className={styles.maincontainer}>
              <img src="./assets/images/PhoneMockup123.png" className={styles.mainImg} />
            </div>
            
</div>
</div>


</div>


</div>

</div>
<Brands/>
<Delivery/>
<Problems/>
<Branding/>
<Easypay/>
<Experience/>
<ServiceLocation/>
<Total/>
<FAQ/>
<Mobile/>
<ContactUs/>


</>
  )
}

export default EtravellerFst
