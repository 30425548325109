import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './sliderProject.module.css';
import { Arow, HomeNext, HomePrev } from '../../components/Svg'; 

const SliderProject = () => {
  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  console.log('Slider settings:', sliderSettings);

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <div className={styles.prev} onClick={() => sliderRef.current?.slickPrev()}>
          <HomePrev />
        </div>
        <div className={styles.next} onClick={() => sliderRef.current?.slickNext()}>
          <HomeNext />
        </div>
      </div>
      <div className={styles.sliderContainer}>
        <Slider ref={sliderRef} {...sliderSettings}>
          <SliderCardThree1 />
          <SliderCardThree2 />
          <SliderCardThree3 />
          <SliderCardThree4 />
        </Slider>
      </div>
    </div>
  );
};

const SliderCardThree1 = () => (
  <div className={styles.slide}>
    <div className={styles.content}>
      <div className={styles.maincontainer}>
        <img src="./assets/images/ImageBG2.png" className={styles.mainImg} alt="SaaS Website" />
      </div>
      <div className={styles.text_content}>
        <div className={styles.text_cntent_end}>
          <div className={styles.text_only}>
            <p className={styles.head}>rd locker</p>
            <p className={styles.title}>Developement . Aug 2024</p>
          </div>
          <div className={styles.button_only}>
  <button onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.rdlocker&pcampaignid=web_share'}>
    See Project <Arow />
  </button>
</div>

        </div>
      </div>
      <div className={styles.last_change}>
      <div className={styles.last_contant}>
      <p className={styles.l_text}>  rdlocker is designed to safely store passwords, contacts, photos, videos, audios, and documents.
  Assign a nominee who can access your data when needed so that you can safeguard your crucial information.
  Download from: <a href="https://play.google.com/store/apps/details?id=com.rdlocker" target="_blank" rel="noopener noreferrer">
    https://play.google.com/store/apps/details?id=com.rdlocker
  </a></p>
      </div>
      </div>
    </div>
  </div>
);

const SliderCardThree2 = () => (
  <div className={styles.slide}>
    <div className={styles.content}>
      <div className={styles.maincontainer}>
        <img src="./assets/images/Group132.png" className={styles.mainImg} alt="Car Dealership App" />
      </div>
      <div className={styles.text_content}>
        <div className={styles.text_cntent_end}>
          <div className={styles.text_only}>
            <p className={styles.head}>E-traveller
            </p>
            <p className={styles.title}>Coming soon</p>
          </div>
          <div className={styles.button_only}>
            <button>See Project <Arow /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const SliderCardThree3 = () => (
  <div className={styles.slide}>
    <div className={styles.content}>
      <div className={styles.maincontainer}>
        <img src="./assets/images/ImageBG4.png" className={styles.mainImg} alt="SaaS Website" />
      </div>
      <div className={styles.text_content}>
        <div className={styles.text_cntent_end}>
          <div className={styles.text_only}>
            <p className={styles.head}>CRM</p>
            <p className={styles.title}>Developement . Aug 2024</p>
          </div>
          <div className={styles.button_only}>
            <button>See Project <Arow /></button>
          </div>
        </div>
      </div>
      <div className={styles.last_change}>
      <div className={styles.last_contant}>
      <p className={styles.l_text}> Customised web CRM for managing your day to day sales, generating invoices, purchase orders, registering your employees data and vehicle logs under admin control.
      General dashboard to view the overall progress of an organization in one click."</p>
      </div>
      </div>
    </div>
  </div>
);
const SliderCardThree4 = () => (
  <div className={styles.slide}>
    <div className={styles.content}>
      <div className={styles.maincontainer}>
        <img src="./assets/images/1410.png" className={styles.mainImg} alt="Car Dealership App" />
      </div>
      <div className={styles.text_content}>
        <div className={styles.text_cntent_end}>
          <div className={styles.text_only}>
            <p className={styles.head}>Softwares
            </p>
            <p className={styles.title}>Coming soon</p>
          </div>
          <div className={styles.button_only}>
            <button>See Project <Arow /></button>
          </div>
        </div>
      </div>
      <div className={styles.last_change}>
      <div className={styles.last_contant}>
      <p className={styles.l_text}> Customised Software solutions for your organization productivity, sales, inventory, accounting, daily timesheet, task management and many more..</p>
      </div>
      </div>
    </div>
  </div>
);
export default SliderProject;
