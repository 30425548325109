import React from 'react'
import styles from "./easypay.module.css"
import Payment from '../payment/Payment'
const Easypay = () => {
  return (
    <div className={styles.container}>
         <div className={styles.content}>
       <div className={styles.frist}>
    <h2>Simple, easy pricing</h2>
      <p className={styles.title}>Amet minim mollit non deserunt ullamco. </p>
      </div>
      </div>
      <Payment/>
      </div>
  )
}

export default Easypay
