import React, { useEffect, useState } from 'react'
import 'quill/dist/quill.snow.css'
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
    const [data, setData] = useState("");
    const pathName = useLocation();
    const path = pathName.pathname.split("/")[1]

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data, status } = await axios.get(`https://backend.rdlocker.com/aradee/user/privacy-policy?userType=${path === "vendor-policy" ? "0" : path === "etraveller-policy" ? "2" : "1"}`)

                if (status === 200) {
                    setData(data.data)
                }
            } catch (err) {
                console.log(err)
            }
        }

        fetch();
    }, [])
    return (
        <>
            <div className='editor-ql-container'>
                <div className="ql-editor">
                    <div className="ql-container ql-snow">
                        <div className='ql-editor'>
                            <div dangerouslySetInnerHTML={{ __html: data }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy