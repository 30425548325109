import React from 'react'
import styles from "./serviceLocation.module.css"
import Anywhere from "../anywhere/Anywhere"



    const ServiceLocation = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
      
        <h2>Our Service Location</h2>
        <p className={styles.title}>Reach us anywhere around</p>
     
      </div>
    <Anywhere/>
   

      </div>
  )
}

export default ServiceLocation
