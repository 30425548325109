import React from 'react'
import { useLocation } from 'react-router-dom';
import styles from './hero2.module.css'
import { Call, Mail1 } from '../Svg';
const ContactCard = () => {
    const location = useLocation();
    const path = location.pathname.split('/')[1]
  return (
    <>
      {
        path === 'contact' ? 
        <div className={styles.number}>
          <div className={styles.step1}>
           < Mail1 />
            <span>
             
                <h5 className={styles.sect}> info@aradeeventures.com</h5>
            </span>
            
        </div>
          <div className={styles.step2}>
           <Call />
            <span>
             
                <h5 className={styles.sect}>(+91) 81233 11094</h5>
            </span>
            
        </div>
        </div>
        : ''
      }
    </>
  )
}

export default ContactCard
