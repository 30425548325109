import React from 'react'
import styles from "./ourVision.module.css"
const OurVision = () => {
  return (
    <div className={styles.container}>
         <div className={styles.contant}>
<h2 className={styles.heading}>Our Mission</h2>
<p className={styles.text}>To empower businesses through innovative software solutions that drive growth and efficiency.</p>
         </div>
    </div>
  )
}

export default OurVision