import React, { useState } from 'react';
import styles from './sampleDemo.module.css';
import axios from 'axios';

const SampleDemo = () => {
  const [fullName, setFullName] = useState('');
  const [subject, setSubject] = useState('');
  const [nameError, setNameError] = useState('');

  const nameRegex = /^[a-zA-Z\s]+$/;

  const handleNameChange = (e) => {
    const { value } = e.target;
    if (!nameRegex.test(value)) {
      setNameError('Please enter a valid name (letters and spaces only).');
    } else {
      setNameError('');
    }
    setFullName(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nameRegex.test(fullName)) {
      setNameError('Please enter a valid name (letters and spaces only).');
      return;
    }

    // Handle form submission logic here
    console.log(fullName, subject);

    try {
      const response = await axios.post('https://api.sampleapis.com/beers/ale', {
        
        name: fullName,
        rating: {},
        image: "",
      });
      if (response.data) {
        console.log(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputBox}>
          <label>Full Name *</label>
          <input
            type="text"
            placeholder="Enter your full name"
            value={fullName}
            onChange={handleNameChange}
            required
          />
          {nameError && <span className={styles.error}>{nameError}</span>}
        </div>

        <div className={styles.inputBox}>
          <label>Place *</label>
          <input
            type="text"
            placeholder="place"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <br />
        <div className={styles.button}>
          <button type="submit">Send Message</button>
        </div>
      </form>
    </div>
  );
};

export default SampleDemo
