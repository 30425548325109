import React from 'react'
import styles from "./delivery.module.css"

import Experts from '../experts/Experts'
const Delivery = () => {
    return (
        <div className={styles.container}>
        <div className={styles.contents}>
     
     <div className={styles.box}>
    
    <div className={styles.section}>
    
    <div className={styles.content}>
    
    <p className={styles.subtitle}>Few lines About this application</p>
             
             
    
    
    </div>
    </div>
    <div className={styles.section1}>
    
    <div className={styles.content}>
    <p className={styles.text}>Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for </p>
    </div>
    </div>
    </div>
     </div>
   <Experts/>
     </div>
            
      )
    }

export default Delivery
