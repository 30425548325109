import React from 'react';
import styles from './policySheet.module.css';

const PolicySheet = () => {
  const spanStyles = {
    fontWeight: 400, // Make the text bold


   
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.heading_01}>Privacy Policy</h1>
      <h2 className={styles.heading_02}>1. Introduction</h2>
<p>Welcome to Aradee Ventures ("we", "our", "us"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our software and services. By using our products and services, you agree to the terms of this Privacy Policy.</p>

<h2 className={styles.heading_02}>2. Information We Collect</h2>
<ul>
  <li>Personal Information: <span style={spanStyles}>This includes information you provide directly to us, such as your name, email address, phone number, and payment information.</span></li>
  <li>Usage Data: <span style={spanStyles}>We collect information about how you use our software and services, including your IP address, device information, and usage patterns.</span></li>
  <li>Cookies and Tracking: <span style={spanStyles}>We use cookies and similar technologies to enhance your experience, analyze usage, and personalize content.</span></li>
</ul>

<h2 className={styles.heading_02}>3. How We Use Your Information</h2>
<ul>
  <li>To Provide and Improve Our Services: <span style={spanStyles}>We use your information to deliver, maintain, and improve our software and services.</span></li>
  <li>To Communicate With You: <span style={spanStyles}>We may use your contact information to send you updates, newsletters, and promotional materials.</span></li>
  <li>For Analytics: <span style={spanStyles}>We analyze usage data to understand how our software is used and to improve user experience.</span></li>
  <li>To Ensure Security: <span style={spanStyles}>We use your information to monitor for and prevent fraud, abuse, and other security threats.</span></li>
</ul>

<h2 className={styles.heading_02}>4. Sharing Your Information</h2>
<ul>
  <li>With Third-Party Service Providers: <span style={spanStyles}>We may share your information with trusted third parties who perform services on our behalf, such as payment processing and data analysis.</span></li>
  <li>For Legal Reasons: <span style={spanStyles}>We may disclose your information if required by law or in response to legal processes.</span></li>
  <li>In Business Transfers: <span style={spanStyles}>If we are involved in a merger, acquisition, or any form of sale of some or all of our assets, your information may be transferred as part of that transaction.</span></li>
</ul>

<h2 className={styles.heading_02}>5. Data Security</h2>
<p>We implement reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.</p>

<h2 className={styles.heading_02}>6. Your Rights and Choices</h2>
<ul>
  <li>Access and Update: <span style={spanStyles}>You may access and update your personal information by contacting us.</span></li>
  <li>Opt-Out: <span style={spanStyles}>You may opt-out of receiving promotional communications from us by following the unsubscribe instructions in those communications.</span></li>
  <li>Data Deletion: <span style={spanStyles}>You may request the deletion of your personal information by contacting us.</span></li>
</ul>

<h2 className={styles.heading_02}>7. Children's Privacy</h2>
<p>Our software and services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.</p>

<h2 className={styles.heading_02}>8. Changes to This Privacy Policy</h2>
<p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the effective date. Your continued use of our software and services after any changes indicates your acceptance of the new Privacy Policy.</p>

<h2 className={styles.heading_02}>9. Contact Us</h2>
<p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
<p>Aradee Ventures<br />
Bangalore, India.<br />
info@aradeeventures.com</p>



     
      </div>
  );
};

export default PolicySheet;
