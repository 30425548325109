import React, { useEffect } from 'react';
import AboutCompan from '../../smallcomponents/aboutcompany/AboutCompan';
import Business from '../../smallcomponents/business/Business';
import Besic from '../besic/Besic';
import FAQ from '../../smallcomponents/faq/FAQ';
import ContactUs from '../../smallcomponents/contactUs/ContactUs';
import styles from "./home.module.css";
import { useLocation } from 'react-router-dom';

const Home = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
        const section = document.getElementById(hash.substring(1));
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
  }, [hash]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.frist}>
          <h2 className={styles.titles}>
            <span className={styles.heading1}> EMPOWERING</span>
            <span className={styles.heading4}><br /> YOUR<span className={styles.heading5}> DIGITAL</span> </span>
            <span className={styles.heading2}>TRANSFORMATION</span>
          </h2>
          <p className={styles.text}> Innovative software solutions tailored to your business needs.</p>
        </div>
        <div className={styles.image}>
          <div className={styles.maincontainer}>
            <img src="./assets/images/Image123.png" className={styles.mainImg_1} />
          </div>
        </div>
        <div className={styles.co_heading}>
          <h6 className={styles.text_co}>Welcome to ARaDee ventures, where technology meets innovation. We specialize in providing custom software solutions that drive efficiency, enhance user experience, and deliver tangible results.</h6>
        </div>
      </div>
      <AboutCompan />
      <div id="services-section">
        <Business />
      </div>
      <div id="project-section">
        <Besic />
      </div>
      <FAQ />
      <ContactUs />
    </>
  );
};

export default Home;
