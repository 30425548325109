import React from 'react'
import styles from './anywhere.module.css'

const Anywhere = () => {
  return (
    <div className={styles.container}>
   
      <div className={styles.wrapper}>
                <div className={styles.slider}>
            <div className={styles.slidetrack}>
            <div  className={styles.slide}>
                
                          <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                        <div  className={styles.slide}>
                        <img className={styles.hide} src="./assets/images/map1.svg" alt="" />
                        </div>
                       
             
         </div>
        </div>
      </div>
    </div>
  )
}


export default Anywhere
