import React from 'react'
import styles from "./besic.module.css"
import SliderProject from "../../smallcomponents/sliderProject/SliderProject"

const Besic = () => {
  return (
    <div className={styles.container}>
    <div className={styles.contents}>
 
 <div className={styles.box}>

<div className={styles.section}>

<div className={styles.content}>

<p className={styles.subtitle}>OUR PORTFOLIO</p>
           <p className={styles.text}>Explore our new recently completed projects.</p>
         
<hr></hr>

</div>
</div>
<div className={styles.section1}>

<div className={styles.content}>


<p className={styles.paragraph}>Our team of experienced developers, designers, and strategists work together to turn your vision into reality.</p>


</div>

</div>

</div>
 </div>
<SliderProject/>
 </div>
  )
}

export default Besic
