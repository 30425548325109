import React from 'react'
import styles from "./careersLast.module.css"
import { NavLink } from 'react-router-dom'
const CareersLast = () => {
  return (
    <div>
    <div className={styles.container}>
    <div className={styles.contant}>
    <h5 className={styles.heading}>Would you like to join our team? </h5>
    <h5 className={styles.text01}>  </h5>
    <h5 className={styles.botton01}><NavLink to="/services" style={{ textDecoration: 'none' }}><button> Join Us now</button> </NavLink></h5>
    </div>

    </div>
  </div>
  )
}

export default CareersLast
