import React from 'react'
import Hero2 from '../hero2/Hero2'
import AboutCompan from '../../smallcomponents/aboutcompany/AboutCompan'
import Trust from '../../smallcomponents/trust/Trust'
import OurMission from '../../smallcomponents/ourMission/OurMission'
import Rating from "../../smallcomponents/rating/Rating"
import FAQ from '../../smallcomponents/faq/FAQ'
import JoinOurTeam from '../../smallcomponents/joinOurTeam/JoinOurTeam'
import OurVision from '../../smallcomponents/ourVision/OurVision'


const About = () => {
  return (
   <>
   <Hero2 
   
    title='About Us'
    subtitle="We are Aradee ventures"
    
    bg='black'

   
    />
    <AboutCompan/>
     {/* <Trust/> */}
     <OurVision/>
     <OurMission/>
     <Rating/>
    <JoinOurTeam/>
    <FAQ/>
    
    </>
  )
}

export default About
