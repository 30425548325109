import React from 'react'
import styles from './rating.module.css'
import { Best1, Best2, Best3, Best4, Best5, Best6 } from '../../components/Svg'

const Rating = () => {
  return (
    <div className={styles.container}>
       <div className={styles.frist}>
    <h2>Our Values</h2>
      
      </div>
<div className={styles.box1}>
    
    <div className={styles.section}>
  
  <div className={styles.content}>
    <Best1/>
  <p className={styles.subtitle}>Innovation</p>
                <p className={styles.text}>Continuously pushing the boundaries of technology.</p>
              

  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
    <Best2/>
  <p className={styles.subtitle}>Quality</p>
  <p className={styles.text}>Delivering solutions that meet the highest standards.</p>

 
  </div>

 </div>
 <div className={styles.section}>
  
  <div className={styles.content}>
    <Best3/>
  <p className={styles.subtitle}>Integrity</p>
  <p className={styles.text}>Building trust through transparency and honesty.</p>
  
 
  </div>
  </div>
 
  <div className={styles.section}>
  
  <div className={styles.content}>
    <Best6/>
  <p className={styles.subtitle}>Customers Success</p>
  <p className={styles.text}>Your success is our priority. </p>
  
 
  </div>
  </div>
</div>
</div>
  )
}

export default Rating
