import React, { useState } from 'react'
import styles from './faq.module.css'
import { Faq } from '../../components/Svg'
const FAQ = () => {
  const [ans, setAns] = useState(null)

    const handleToggle = (index) => {
        setAns(ans === index ? null : index)
    }
  return (
    <div className={styles.container}>
      <h2>FAQ</h2>
    
      <br />
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(1)}>
        <p className={styles.question}>1. What is RDLocker?</p>
        <Faq id = {ans=== 1} />
      </div>
      {
        ans === 1 ?
        <div className={styles.answer}>
          RDLocker is a mobile app designed to securely store your crucial documents, passwords, and other important information. It provides a safe and convenient way to manage and access your data anytime, anywhere.
        </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(2)}>
        <p className={styles.question}>2. How do I download the RDLocker app?</p>
        <Faq id = {ans=== 2} />
        
      </div>
      {
        ans === 2 ?
        <div className={styles.answer}>
       You can download the RDLocker app from the [App Store] for iOS devices and [Google Play] for Android devices. Simply search for "RDLocker" and follow the installation instructions.
             </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(3)}>
        <p className={styles.question}>3. Is my information safe with RDLocker?</p>
        <Faq id = {ans=== 3} />
      </div>
      {
        ans === 3 ?
        <div className={styles.answer}>
      Yes, your information is safe with RDLocker. We use state-of-the-art encryption technology to protect your data. Only you and the people you designate can access your stored information.
         </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(4)}>
        <p className={styles.question}>4. How do I store documents in RDLocker?</p>
        <Faq id = {ans=== 4} />
      </div>
      {
        ans === 4 ?
        <div className={styles.answer}>
       To store documents, open the RDLocker app and navigate to the "Documents" section. Tap the "+" icon to upload documents from your device or take a photo of the document directly through the app.
 </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(5)}>
        <p className={styles.question}>5. Can I store passwords in RDLocker?</p>
        <Faq id = {ans=== 5} />
      </div>
      {
        ans === 5 ?
        <div className={styles.answer}>
       Absolutely! RDLocker includes a secure password manager. Go to the "Passwords" section in the app, tap the "+" icon, and enter your password details. Your passwords will be encrypted and securely stored. 
        </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(6)}>
        <p className={styles.question}>6. What is a nominee, and how do I set one up?</p>
        <Faq id = {ans=== 6} />
      </div>
      {
        ans === 6 ?
        <div className={styles.answer}>
      A nominee is a trusted person you designate to access your information in case of an emergency. To set up a nominee, go to the "Nominee" section in the app, enter the nominee's contact details, and follow the verification steps.
  </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(7)}>
        <p className={styles.question}>7. What happens if I lose my phone?</p>
        <Faq id = {ans=== 7} />
      </div>
      {
        ans === 7 ?
        <div className={styles.answer}>
      If you lose your phone, your data remains secure. You can restore your data on a new device by downloading the RDLocker app, logging into your account, and selecting the "Restore" option in the "Settings" section.
  </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(8)}>
        <p className={styles.question}>8. Is there a cost to use RDLocker?</p>
        <Faq id = {ans=== 8} />
      </div>
      {
        ans === 8 ?
        <div className={styles.answer}>
    RDLocker offers both free and premium plans. The free plan includes basic features, while the premium plan offers additional storage and advanced features. Visit the "Pricing" section of our website for more details.
  </div> : ''
      }
      </div>
      <div className={styles.aligned}>
      <div className={styles.box} onClick={()=>handleToggle(9)}>
        <p className={styles.question}>9. How do I contact customer support?</p>
        <Faq id = {ans=== 9} />
      </div>
      {
        ans === 9 ?
        <div className={styles.answer}>
    If you have any questions or need assistance, our customer support team is here to help. You can reach us through the "Contact Us" section in the app
  </div> : ''
      }
      </div>
    </div>
  )
}

export default FAQ
