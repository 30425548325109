import React from 'react'
import styles from "./experts.module.css"

const Experts = () => {
  return (
    <div className={styles.container}>
   
    <div className={styles.box}>
        <div className={styles.section}>
        <div className={styles.div_box}>
        <div className={styles.sec_01}>
        <p className={styles.ma_text1}>E-traveller</p>
         <p className={styles.ma_text2}>Dummy content about the can be explained here in detail. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> 
         </div>
                <div className={styles.maincontainer}>
               
          <img src="./assets/images/main.png" className={styles.mainImg} />
          
   </div>     
         </div>
        
</div>
<div className={styles.section_11}>
        <div className={styles.div_box}>
        <div className={styles.sec_01}>
        <p className={styles.ma_text1}>Experts & Delivery agent</p>
         <p className={styles.ma_text2}>Dummy content about the can be explained here in detail. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> 
         </div>
                <div className={styles.maincontainer}>
               
          <img src="./assets/images/main.png" className={styles.mainImg} />
          
   </div>     
         </div>
        
</div>

</div>
</div>

  )
}

export default Experts
