import React from 'react'
import styles from './customers.module.css'
import { Star5 } from '../../components/Svg'
const Customers = () => {
  return (
    <div className={styles.container}>
   
      <div className={styles.wrapper}>
                <div className={styles.slider}>
            <div className={styles.slidetrack}>
                        <div  className={styles.slide}>
                     <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Gabrile Jackson</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch.  </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                     <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Gabrile Jackson</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch.  </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                     <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Gabrile Jackson</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch.  </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                     <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Gabrile Jackson</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch.  </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                     <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Gabrile Jackson</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch.  </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.slide}>
                        <div  className={styles.sl_s1}>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable.</p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic4.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        <div  className={styles.box1}>
                        <p className={styles.text_12}>Teamollo delivered the site with inthe timeline as they requested. Inthe end, the client found a 50% increase in traffic with in days since its launch. They also had an impressive ability to use technologies that the company hasn`t used, which have also proved to be easy to use and reliable. </p>
                        <div  className={styles.customer}>
                        <img src="./assets/images/Pic2.svg" className={styles.mainImg} style={{ width: '30%', height: 'auto' }} />
                        <div  className={styles.customer_name}>
                            <p className={styles.name1}>Ashley Cooper</p>
                            <Star5/>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        
         </div>
        </div>
      </div>
    </div>
  )
}

export default Customers
