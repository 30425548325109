import React from 'react'
import Hero2 from '../hero2/Hero2'
import PolicySheet from '../../smallcomponents/policySheet/PolicySheet'

const Privacy = () => {
  return (
    <>
    <Hero2 
   
    title='Policies'
    subtitle="Privacy Policy"
   

    bg='black'
   
    />
    <PolicySheet/>
    </>
  )
}

export default Privacy
