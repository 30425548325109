import React, { useState, useEffect } from 'react';
import styles from './header.module.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Hamburger } from '../Svg';
import { IoIosArrowDown } from "react-icons/io";

const Header = () => {
    const [sidebar, setSidebar] = useState(false);
    const navigate = useNavigate();
    const pathName = useLocation();
    const path = pathName.pathname.split("/")[1]

    const excludes = ["vendor-policy", "etraveller-policy", "agent-policy"]

    const handlenav = () => {
        setSidebar(!sidebar);
    };

    const handleScrollToSection = (e, sectionId) => {
        e.preventDefault();
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        navigate(`/#${sectionId}`);
    };

    useEffect(() => {
        const handleHashChange = () => {
            const sectionId = window.location.hash.replace('#', '');
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        excludes.includes(path) ? <></> : <div className={styles.container}>
            <div className={styles.mainHeader}>
                <Hamburger handlenav={handlenav} className={styles.hamburger} />
                <a className={styles.logoName}>
                    <div className={styles.maincontainer}>
                        <NavLink to="/">
                            <img src="./assets/images/LOGO.png" className={styles.mainImg} />
                        </NavLink>
                    </div>
                </a>
                <span>
                    <div className={sidebar ? `${styles.opened} ${styles.rightNav}` : styles.rightNav}>
                        <NavLink className={styles.navmenu} onClick={handlenav} to='/'>Home</NavLink>
                        <NavLink className={styles.navmenu} onClick={handlenav} to='/about'>About</NavLink>
                        <NavLink
                            className={styles.navmenu}
                            onClick={(e) => handleScrollToSection(e, 'services-section')}
                            to='/#services-section'
                        >
                            Services
                        </NavLink>
                        <div className={`${styles.pages} ${styles.navmenu}`}>
                            <NavLink
                                className={styles.navmenu}
                                onClick={(e) => handleScrollToSection(e, 'project-section')}
                                to='/#project-section'
                            >
                                Projects
                            </NavLink>
                            <IoIosArrowDown className={styles.dropdown} />
                            <div className={styles.list}>
                                <div className={styles.contentus}>
                                    <div className={styles.category1}>
                                        <h4 className={styles.title}>Mobile Apps</h4>
                                        <p className={styles.item}>
                                            <NavLink
                                                to="/#project-section"
                                                className={styles.link}
                                                onClick={(e) => handleScrollToSection(e, 'project-section')}
                                            >
                                                rd Locker
                                            </NavLink>
                                        </p>
                                        <p className={styles.description}>
                                            rdlocker is designed to safely store passwords, contacts, photos, videos, audios, and documents.
                                            Assign a nominee who can access your data when needed so that you can safeguard your crucial information.
                                            Download from: <a href="https://play.google.com/store/apps/details?id=com.rdlocker" target="_blank" rel="noopener noreferrer">
                                                https://play.google.com/store/apps/details?id=com.rdlocker
                                            </a>
                                        </p>
                                    </div>
                                    <div className={styles.category2}>
                                        <h4 className={styles.title}>Web Apps</h4>
                                        <p className={styles.item}>
                                            <NavLink
                                                to="/#project-section"
                                                className={styles.link}
                                                onClick={(e) => handleScrollToSection(e, 'project-section')}
                                            >
                                                CRM
                                            </NavLink>
                                        </p>
                                        <p className={styles.description}>
                                            Our CRM app is designed to help businesses streamline their customer interactions, improve sales, and enhance customer satisfaction. Whether you're a small startup or a large enterprise, our CRM solution provides the tools you need to manage your customer relationships effectively.
                                        </p>
                                        <p className={styles.item}>
                                            <NavLink
                                                to="/#project-section"
                                                className={styles.link}
                                                onClick={(e) => handleScrollToSection(e, 'project-section')}
                                            >
                                                Softwares
                                            </NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavLink className={styles.navmenu} onClick={handlenav} to='/contact'>Contact Us</NavLink>
                    </div>
                </span>
                <button onClick={() => navigate('/contact')} className={styles.connect}>Let’s Talk</button>
            </div>
        </div>
    );
}

export default Header;
